import Link from "next/link";
import Image from "next/image";

declare interface Props {
  height?: number;
  width?: number;
}
export const PlayStoreDownloadImage = ({ height, width }: Props) => {
  return (
    <Link
      className=" inline-block  aspect-[234.75/71.41]"
      href="https://play.google.com/store/apps/details?id=com.freeconvert.collagemaker"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        width={width ?? 150}
        height={height ?? 60}
        src="/static/download-on-the-play-store.svg"
        alt="Download app form play store"
      />
    </Link>
  );
};
