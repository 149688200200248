import React from "react";
import {FileUploadDropZone} from "../components/common/FileUploader/FileUploadDropZone";
import {Footer} from "../components/modules/Footer/Footer";
import Head from "next/head";
import {HomePageCollageMakerSection} from "../components/Features/HomePage/HomePageCollageMakerSection";
import {HomePageHero} from "../components/Features/HomePage/HomePageHero";
import {HomePagePhotoJoinerSection} from "../components/Features/HomePage/HomePagePhotoJoinerSection";

import {Navbar} from "../components/modules/Navbar/Navbar";
import type {NextPage} from "next";
import RouteNames from "../Routes/RouteNames";
import {appColors} from "../components/common/Colors";
import {UseMobileAppDialog} from "../components/common/UseMobileAppDialog";
import {isMobile} from "react-device-detect";

const Page: NextPage = () => {
  return (
    <div className="">
      <Head>
        <link rel="canonical" href="https://www.photojoiner.com" />
        {isMobile ? (
          <></>
        ) : (
          <script
            src="https://sak.userreport.com/photopix/launcher.js"
            async
            id="userreport-launcher-script"
          ></script>
        )}
      </Head>
      <FileUploadDropZone
        location="web_home"
        routeName={RouteNames.collageEditor}
      >
        <Navbar backgroundColor={appColors.siteHeroBg} />
        <HomePageHero
          backgroundColor={appColors.siteHeroBg}
          textColor="black"
          circle1Color="#FF7E11"
          circle2Color="#FFEAD8ea"
        />

        <HomePageCollageMakerSection
          backgroundColor="#FFFFFF"
          circle1Color="#FA4772"
          circle2Color="#FFC3D2"
          dots1Color="#F8BECC"
          dots2Color="#F54670"
        />
        <HomePagePhotoJoinerSection
          backgroundColor="#F8FAFF"
          shape1Color="#F2994A"
          shape2Color="#FFE6D0"
          dots2Color="#ED9649"
        />
        {/* <Section4
                backgroundColor="#FFFFFF"
                circle3Color="#46B3A5"
                circle4Color="#B3EBE4"
                dots2Color="#46B0A3"
                imageSrc="/2/section-4.png"
            /> */}
        {/* <Section5 backgroundColor="#F8FAFF" /> */}
        {/* <HomeDownloadMobileApp
                backgroundColor="#FFFFFF"
            />  */}
        <Footer
          titleColor="#F8F8F8"
          backgroundColor="#FFFFFF"
          textColor="#333333"
          whiteLogo={false}
        />
      </FileUploadDropZone>
      <UseMobileAppDialog />
    </div>
  );
};

export default Page;