import {Dots} from "../../Shapes/Dots/Dots";
import RouteNames from "../../../Routes/RouteNames";
import {Shape1} from "../../Shapes/Section3/Shape1";
import {Shape2} from "../../Shapes/Section3/Shape2";
import {StitchPicturesButton} from "../../common/StitchPicturesButton/StitchPicturesButton";
import {logFirebaseEvent} from "../../../helpers/firebase_helper";
import Image from "next/image";
import collageHomeImage3 from "../../../../public/static/home_3.webp";
import collageHomeImage3Mobile from "../../../../public/static/home_3_mobile.webp";
import {isMobile} from "react-device-detect";
import Link from "next/link";

export interface IHomePageIPhotoJoinerSectionProps {
  backgroundColor: string;
  shape1Color?: string;
  shape2Color?: string;
  dots1Color?: string;
  dots2Color?: string;
}

export const HomePagePhotoJoinerSection = ({
  backgroundColor,
  shape1Color = "transparent",
  shape2Color = "transparent",
  dots1Color = "transparent",
  dots2Color = "transparent",
}: IHomePageIPhotoJoinerSectionProps) => {
  return (
    <div className="py-16 lg:py-36 px-4 xl:px-52" style={{ backgroundColor }}>
      <div className="flex flex-col lg:flex-row justify-center gap-8">
        <div className="w-full flex flex-col items-center justify-center gap-4 lg:px-4">
          <div className="flex flex-col gap-4 ">
            <Link href={RouteNames.featureStitchPictures}>
              <h2 className="text-5xl lg:text-6xl">Photo Joiner</h2>
            </Link>
            <div
              className="leading-relaxed lg:max-w-md"
              // style={{ maxWidth: "45ch" }}
            >
              Quickly stitch together 2 or more photos to create a panorama or a
              photo series. With our photo stitch tool you can join photos
              horizontally or vertically. With our Photo Joiner tool, you can
              easily adjust the background size or border radius. Bring your
              stitched photos to life by adding text, stickers, background
              patterns, or even doodle on your stitched photos to male them
              personal.
            </div>
            <div>
              <StitchPicturesButton
                onFileSelect={() => {
                  logFirebaseEvent("cus_select_photos", {
                    location: "web_home",
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex">
          <div className="relative  w-full aspect-[1.18]">
            {/* <img src={imageSrc} className="opacity-0" alt="Photo stitch tool by PhotoJoiner"/> */}

            <div className="absolute inset-0 z-10 flex shadow-lg rounded-xl w-full h-full">
              <Image
                src={isMobile? collageHomeImage3Mobile:collageHomeImage3}
                width={isMobile ? 500 : 1000}
                sizes={`max-width: ${isMobile?500:1000}px`}
                className="rounded-xl "
                alt="PhotoJoiner stitch photos screen shot"
              />
            </div>

            <div className="hidden lg:block absolute left-0 top-0 -translate-x-1/4 -translate-y-1/4">
              <Shape1 fill={shape1Color} />
            </div>

            <div className="hidden lg:block absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2">
              <Shape2 fill={shape2Color} />
            </div>

            <div className="hidden lg:block absolute right-1/2 top-0 -translate-y-1/4 translate-x-full">
              <Dots fill={dots1Color} />
            </div>

            <div className="hidden lg:block absolute right-0 bottom-0 translate-x-1/4 translate-y-1/4">
              <Dots fill={dots2Color} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
