import RouteNames from "../../../Routes/RouteNames";
import {isSafari} from "react-device-detect";
import {openFileSelector} from "../../../helpers/file_picker";
import {useRouter} from "next/router";

export interface IGetStartedButtonProps {
  text?: string;
  onFileSelect?: () => void;
}

// async function fetchFlutterDependencies(){
//   try{
//    await fetch("/web/main.dart.js");
//    await  fetch("https://unpkg.com/canvaskit-wasm@0.33.0/bin/canvaskit.js");
//    await  fetch("https://unpkg.com/canvaskit-wasm@0.33.0/bin/canvaskit.wasm");
//   }catch (e){
//       console.log(e);
//   }
//}

export const GetStartedButton = (props: IGetStartedButtonProps) => {
  const router = useRouter();

  async function handleFilePick(): Promise<boolean> {
    var files: string[] = await openFileSelector({
      accept: [".jpg", ".png", ".jpeg", ".gif", ".webp"],
    });
    // console.log(files.length);
    (window as any).state = {
      selectedFiles: files,
    };

    var iframe = document.getElementById("cm_iframe") as any;
    if (iframe != null) {
      iframe.contentWindow.postMessage({ fileLoaded: true }, "*");
    }

    return files.length != 0;
  }

  return (
    <div
      className="cursor-pointer inline-block bg-orange py-4 px-6 rounded-l-full rounded-r-full text-white shadow-sm"
      style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
      onClick={async () => {
        if (isSafari) {
          const res = await handleFilePick();
          console.log("handleFilePick "+res);
          void router.push(RouteNames.collageEditor);

          if (res) {
            props.onFileSelect?.();
          }
        } else {
          void router.push(RouteNames.collageEditor);
          const res = await handleFilePick();
          console.log("handleFilePick "+res);
          if (res) {
            props.onFileSelect?.();
          }
        }
      }}
    >
      {props.text ?? "Get Started"}
    </div>
  );
};
