import {Circle1} from "../../Shapes/Section2/Circle1";
import {Circle2} from "../../Shapes/Section2/Circle2";
import {Dots} from "../../Shapes/Dots/Dots";
import {GetStartedButton} from "../../common/GetStartedButton/GetStartedButton";
import RouteNames from "../../../Routes/RouteNames";
import {logFirebaseEvent} from "../../../helpers/firebase_helper";
import {useRouter} from "next/router";
import Image from "next/image";

import collageHomeImage2 from "../../../../public/static/home_2.webp";
import collageHomeImage2Mobile from "../../../../public/static/home_2_mobile.webp";
import {isMobile} from "react-device-detect";
import Link from "next/link";

export interface ISection2Props {
  backgroundColor: string;
  circle1Color: string;
  circle2Color: string;
  dots1Color?: string;
  dots2Color?: string;
}

export const HomePageCollageMakerSection = ({
  backgroundColor,
  circle1Color: circle1Color,
  circle2Color: circle2Color,
  dots1Color = "transparent",
  dots2Color = "transparent",
}: ISection2Props) => {
  const router = useRouter();
  return (
    <div className="py-24 px-4 lg:px-0 xl:px-52" style={{ backgroundColor }}>
      <div className="flex flex-col-reverse lg:flex-row ">
        <div className="w-full flex justify-end ">
          <div className="relative my-16 w-full aspect-[1.18]">
            {/* <img src={imageSrc} className="opacity-0"  alt="Collage Maker by PhotoJoiner"/> */}
            <div className="absolute inset-0 z-10 flex rounded-xl">
              <Image
                src={isMobile? collageHomeImage2Mobile:collageHomeImage2}
                width={isMobile ? 500 : 100}
                sizes={`max-width: ${isMobile?500:1000}px`}
                className="shadow-lg  w-full h-full"
                alt="PhotoJoiner collage maker screen shot"
              />
            </div>

            <div className="hidden lg:block absolute top-0 left-0 -translate-x-1/4 -translate-y-16">
              <Circle1 fill={circle1Color} />
            </div>

            <div
              className={`hidden lg:block absolute bottom-0 left-1/2 translate-y-1/3 ${
                dots2Color === "transparent"
                  ? "translate-x-1/2"
                  : "-translate-x-1/3"
              }`}
            >
              <Circle2 fill={circle2Color} />
            </div>

            <div className="hidden lg:block absolute right-0 top-0 -translate-x-1/3 -translate-y-1/4">
              <Dots fill={dots1Color} />
            </div>

            <div className="hidden lg:block absolute right-0 bottom-0 translate-x-1/4 translate-y-1/4">
              <Dots fill={dots2Color} />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col ">
          <div className="flex flex-col  gap-4 lg:pl-24 items-start justify-start">
            <Link href={RouteNames.featureCollageMaker}>
              <div
                className="text-5xl lg:text-6xl"
                style={{ maxWidth: "16ch" }}
              >
                <h2>Collage Maker</h2>
              </div>
            </Link>
            <div className="leading-relaxed lg:max-w-md">
              Easily create a beautiful photo collage using PhotoJoiner’s{" "}
              <Link
                className="underline"
                style={{
                  color: "royalblue",
                }}
                href={RouteNames.featureCollageMaker}
                onClick={(e) => {
                  e.preventDefault();
                  router.push(RouteNames.featureCollageMaker);
                }}
              >
                collage maker
              </Link>
              . Use thousands of free templates, stickers, and backgrounds to
              create the perfect collage and bring your photos to life. Plus,
              you can even create collages to perfectly fit platforms like
              Facebook, Twitter, or Instagram. You can even personalize your
              collage with doodles, text, or photo filters to make it your own!
            </div>
            <div className="pt-4">
              <GetStartedButton
                text="Create a Collage"
                onFileSelect={() => {
                  logFirebaseEvent("cus_select_photos", {
                    location: "web_home",
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
