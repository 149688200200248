import Link from "next/link";

declare interface Props {
  height?: number;
  width?: number;
}
export const AppStoreDownloadImage = ({ height, width }: Props) => {
  return (
    <Link
      href="https://apps.apple.com/us/app/collage-maker-photojoiner/id1587802950"
      target="_blank"
      rel="noreferrer"
    >
      <img
        width={width ?? 150}
        height={height ?? 60}
        src="/static/download-on-the-app-store.svg"
        alt="Download app form app store"
      />
    </Link>
  );
};
