import {Circle1} from "../../Shapes/Hero/Circle1";
import {Circle2} from "../../Shapes/Hero/Circle2";
import {Dots} from "../../Shapes/Dots/Dots";
import {GetStartedButton} from "../../common/GetStartedButton/GetStartedButton";
import {HeroAdBanner} from "../../common/HeroAdBanner";
import {logFirebaseEvent} from "../../../helpers/firebase_helper";
import Image from "next/image";
import React, {useEffect} from "react";
import homeImage1 from "../../../../public/static/home_1.webp";
import homeImage1Mobile from "../../../../public/static/home_1_mobile.webp";
import {isMobile} from "react-device-detect";


export interface IHomePageHeroProps {
    backgroundColor: string;
    circle1Color: string;
    circle2Color: string;
    dotsColor?: string;
    textColor?: string;
}

export const HomePageHero = ({
                                 backgroundColor,
                                 circle1Color,
                                 circle2Color,
                                 dotsColor = "#F2994A",
                                 textColor = "#000",
                             }: IHomePageHeroProps) => {

    const [isMobileDevice, setIsMobileDevice] = React.useState(false);

    useEffect(() => {
        if (isMobile) {
            setIsMobileDevice(true);
        }
    }, []);


    return (
        <div
            className="  pt-4 px-4 xl:px-52  flex flex-col"
            style={{color: textColor, backgroundColor}}
        >
            <div className=" pt-32 flex flex-col lg:flex-row  gap-8 py-4">
                <div className="flex justify-center w-full">
                    <div
                        className=" flex flex-col gap-8 items-baseline"
                        style={{maxWidth: "65ch"}}
                    >
                        <div className="flex flex-col text-4xl lg:text-8xl leading-tight  font-medium">
                            <h1>
                                Easy to Use <br/> Collage Editor
                            </h1>
                        </div>
                        <div className="text-2xl ">
                            PhotoJoiner’s easy-to-use collage editor lets anyone create
                            beautiful photo collages in seconds! It comes with thousands of
                            creative assets that are all free to use.
                        </div>
                        <GetStartedButton
                            onFileSelect={() => {
                                logFirebaseEvent("cus_select_photos", {
                                    location: "web_home",
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="w-full flex justify-center  pb-14">
                    <div className="relative bg-red-500 w-full aspect-[1.18]">
                       <div
                           className="absolute inset-0 z-10 "
                       >
                           <Image

                               height={930}
                               width={isMobile ? 500 : 1096}
                               sizes={`max-width: ${isMobile?500:1000}px`}
                               loading={isMobile ? "lazy":"eager"}
                               src={isMobile ? homeImage1Mobile:homeImage1}
                               alt="PhotoJoiner collage maker editor"
                           />
                       </div>

                        <div className="hidden lg:block absolute top-0 left-0 -translate-x-1/2 -translate-y-1/3">
                            <Circle1 fill={circle1Color}/>
                        </div>

                        <div className="hidden lg:block absolute bottom-0 right-0 translate-y-1/3">
                            <Circle2 fill={circle2Color}/>
                        </div>

                        <div className="hidden lg:block absolute top-0 -right-10 -translate-y-1/4">
                            <Dots fill={dotsColor}/>
                        </div>

                        <div className="hidden lg:block absolute bottom-0 left-0 translate-y-1/4 -translate-x-1/4">
                            <Dots fill={dotsColor}/>
                        </div>
                    </div>
                </div>
            </div>
            {isMobileDevice? <> </>:<HeroAdBanner/>}

        </div>
    );
};
