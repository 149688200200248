import {Modal} from "./Modal/Modal";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import {PlayStoreDownloadImage} from "./PlayStoreDownloadImage";
import {AppStoreDownloadImage} from "./AppStoreDownloadImage";
import {useEffect, useState} from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./Dialog/Dialog";

declare interface Props {
  height?: number;
  width?: number;
}
export const UseMobileAppDialog = ({ height, width }: Props) => {
  const [isOpenModal, setModalOpen] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setModalOpen(true);
    }
  }, []);

  return (
    <Dialog
      open={isOpenModal}
      modal={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setModalOpen(false);
        }
      }}
    >
      <DialogContent className="rounded-md">
        <DialogHeader>
          <DialogTitle className="font-bold text-2xl">
            Download our app
          </DialogTitle>
          <DialogDescription>
            Please download our free <strong>Collage Maker</strong> app for a
            much better mobile experience.
          </DialogDescription>
        </DialogHeader>
        <div className="w-full flex items-center justify-center">
          {isAndroid ? <PlayStoreDownloadImage width={200} height={60} /> : ""}
          {isIOS ? <AppStoreDownloadImage width={200} height={60} /> : ""}
        </div>
      </DialogContent>
    </Dialog>
  );
};
